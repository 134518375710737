import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import { authGuard } from "@tt/vue-auth0";

Vue.use(VueRouter);

const routes = [
  {
    path: "/locations",
    name: "locationsOverview",
    menuTitle: i18n.tc("locations.title", 2),
    menuIcon: "mdi-store",
    showIcon: false,
    component: () => import("@/views/LocationsOverview"),
    active: true
  },
  {
    path: "/",
    redirect: "/locations",
    // name: "dashboard",
    // menuTitle: i18n.t("dashboard.title"),
    // menuIcon: "mdi-home-outline",
    // showIcon: false,
    // component: () => import("@/views/Dashboard"),
    showIcon: false,
    active: false
  },
  {
    path: "/location-groups",
    name: "locationGroupsOverview",
    menuTitle: i18n.tc("location-groups.title", 2),
    menuIcon: "mdi-home-group",
    showIcon: false,
    component: () => import("@/views/locationGroupsOverview"),
    active: true
  },
  {
    path: "/users",
    name: "usersOverview",
    menuTitle: i18n.tc("users.title", 2),
    menuIcon: "mdi-account-outline",
    showIcon: false,
    component: () => import("@/views/UsersOverview"),
    active: true
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
