import createClient from "@tt/vue-components/src/services/apiClient";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService";
const apiClient = createClient(EnvProvider.value("ENTITY_API_URL"));

const EntityService = {
  locations: {
    ...baseApiService(apiClient, 'locations'),
  },
  locationGroups: {
    ...baseApiService(apiClient, 'location_groups'),
  },
  drivingRoutes: {
    ...baseApiService(apiClient, 'driving_routes')
  },
  users: {
    ...baseApiService(apiClient, 'users'),
    changePassword: async (userId, data) => {
      let endpoint = "users/" + userId + "/password";
      return await apiClient.put(endpoint, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
    },
  },
  openingHours: {
    ...baseApiService(apiClient, 'opening_hours'),
    sync: async (data) => {
      let endpoint = "opening_hours/location/sync";
      return await apiClient.put(endpoint, data, {
        headers: {
          "Content-Type": "application/ld+json"
        }
      })
    },
    upsert: async (data) => {
      let endpoint = "opening_hours/location/upsert";
      return await apiClient.put(endpoint, data, {
        headers: {"Content-Type": "application/ld+json"}
      })
    },
  },
  openingHoursExceptions: {
    ...baseApiService(apiClient, 'opening_hours_exceptions'),
    deleteAll: async (code) => {
      let endpoint= "opening_hours_exceptions/" + code + "/all";
      return await apiClient.delete(endpoint);
    },
    sync: async (data) => {
      let endpoint = "opening_hours_exceptions/location/sync";
      return await apiClient.put(endpoint, data, {
        headers: {
          "Content-Type": "application/ld+json"
        }
      })
    },
    upsert: async (data) => {
      let endpoint = "opening_hours_exceptions/location/upsert";
      return await apiClient.put(endpoint, data, {
        headers: {
          "Content-Type": "application/ld+json"
        }
      })
    },
  }
}
export default EntityService;
