import Vue from "vue";
import Vuex from "vuex";
import locationsOverview from "@/store/modules/locationsOverview";
import locationGroups from "@/store/modules/locationGroups";
import usersOverview from "@/store/modules/usersOverview";
import locationsList from "@/store/modules/locationsList";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    locationsOverview,
    locationGroups,
    usersOverview,
    locationsList,
  },
  strict: debug,
  plugins: debug ? [Vuex.createLogger()] : [],
  getters: {}
});

export default store;