import {
  tableState,
  tableMutations,
  tableActions
} from "@tt/vue-components"
import entityService from "@/services/EntityService";

const openingHours = {
  namespaced: true,
  state: {
    ...tableState,
    location: null
  },
  mutations: {
    ...tableMutations,
    SET_LOCATION(state, location) {
      state.location = location;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);

        const params = {
          pagination: { page: 1, itemsPerPage: 999},
          sorting: { sortBy: ["day"], sortDesc: [false] },
        };

        const location = context.rootState.locationsOverview.location;

        entityService.locations.getSubResource(location.code, 'opening_hours', params)
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    resetItems(context) {
      context.commit("SET_ITEMS", { items: [], totalItems: 0 } );
    },
    saveHours(context, openingHours) {
      let upsertCalls = [];
      openingHours.forEach(function(slots) {
        // Sanitize some values.
        slots.openTime1 = slots.openTime1 || null;
        slots.openTime2 = slots.openTime2 || null;
        slots.openTime3 = slots.openTime3 || null;
        slots.closeTime1 = slots.closeTime1 || null;
        slots.closeTime2 = slots.closeTime2 || null;
        slots.closeTime3 = slots.closeTime3 || null;
        slots.day = slots.day || null;
        slots.open = true;

        if (
          slots.openTime1 === null &&
          slots.openTime2 === null &&
          slots.openTime3 === null &&
          slots.closeTime1 === null &&
          slots.closeTime2 === null &&
          slots.closeTime3 === null
        ) {
          slots.open = false;
        }

        if (slots["@id"]) {
          upsertCalls.push(entityService.openingHours.update(slots["id"], slots));
        } else {
          upsertCalls.push(entityService.openingHours.create(slots));
        }

      });
      return Promise.all(upsertCalls).then(() => {
        context.dispatch("fetchItems", true);
      });
    },
    isLoading(context, bool) {
      context.state.loading = bool;
    },
    createNewSlots() {
      return {
        openTime1: null,
        openTime2: null,
        openTime3: null,
        closeTime1: null,
        closeTime2: null,
        closeTime3: null,
        open: null,
        day: null,
        location: null
      };
    },
    syncHours(context, request) {
      return new Promise((resolve, reject) => {
        entityService.openingHours[request.method](request.data)
          .then(() => {
            return resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    }
  },
  getters: {
    /**
     * day:
     * 1 = monday
     * 7 = sunday
     */
    openingHoursForDay: (state, getters, rootState) => day => {
      let slots = state.items.find(
        item => parseInt(item.day) === parseInt(day)
      );

      const location = rootState.locationsOverview.location;

      if (slots == null) {
        slots = {
          openTime1: null,
          openTime2: null,
          openTime3: null,
          closeTime1: null,
          closeTime2: null,
          closeTime3: null,
          open: null,
          day: day,
          location: location["@id"]
        };
      }
      return slots;
    }
  }
};

export default openingHours;
