import {
    tableState,
    tableMutations,
    tableActions,
    tableGetters
} from "@tt/vue-components";
import nameFilter from "@/store/modules/locationGroups/filters/nameFilter";
import codeFilter from "@/store/modules/locationGroups/filters/codeFilter";
import isActiveFilter from "@/store/modules/locationGroups/filters/isActiveFilter";
import entityService from "@/services/EntityService";

const locationOverview = {
    requestController: null,
    namespaced: true,
    modules: {
        nameFilter,
        codeFilter,
        isActiveFilter
    },
    state: {
        ...tableState,
        pagination: {itemsPerPage: 20, itemsPage: 1},
        footerProps: {"items-per-page-options": [10, 20, 40, 80]},
        group: null
    },
    mutations: {
        ...tableMutations,
        SET_GROUP(state, group) {
            state.group = group;
        }
    },
    actions: {
        ...tableActions,
        fetchItems({state, commit}, refresh = false) {
            if (refresh || state.items.length === 0) {
                commit("SET_LOADING", true);
                const params = {pagination: state.pagination, sorting: state.sorting, search: state.search};

                if (this.requestController) {
                    this.requestController.abort();
                }
                this.requestController = new AbortController();

                entityService.locationGroups.list(params, { signal: this.requestController.signal })
                    .then(json => {
                        if (json) {
                            commit("SET_ITEMS", json);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        commit("SET_LOADING", false);
                    });
            }
        },
        selectGroup({ commit }, item) {
            commit("SET_ITEM", item);
            commit("SET_GROUP", item);
        },
        edit({commit, dispatch}, group) {
            return new Promise((resolve, reject) => {
               entityService.locationGroups.update(group.code, group)
                    .then((result) => {
                        commit("SET_GROUP", result);
                        commit("SET_ITEM", result);
                        dispatch("fetchItems", true)
                        resolve();
                    })
                    .catch(err => {
                        reject(err["hydra:description"]);
                    });
            });
        },
        add({commit}, group) {
            return new Promise((resolve, reject) => {
                entityService.locationGroups.create(group)
                    .then((data) => {
                        commit("SET_GROUP", data);
                        commit("ADD_ITEM", data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err["hydra:description"]);
                    });
            });
        },
    },
    getters: {
        ...tableGetters,
        getGroupById: state => id => {
            return state.items.find(item => item.id === id);
        }
    }
};

export default locationOverview;
