const barcodeFilter = {
  namespaced: true,
  state: {
    data: "",
    notNull: false,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_NOTNULL(state, notNull) {
      state.notNull = notNull;
    }
  },
  actions: {
    setData(context, value) {
      context.commit("SET_DATA", value);
    },
    setNotNull(context, value) {
      context.commit("SET_NOTNULL", value);
    }
  },
  getters: {
    data: state => {
      return state.data;
    },
    notNull: state => {
      return state.notNull
    },
    toParams: state => {
      const params = [];
      params.push({column: "barcode", value: state.data});

      if (state.notNull === true) {
        params.push({column: "exists[barcode]", value: !state.notNull})
      }
      return params;
    }
  }
};

export default barcodeFilter;
