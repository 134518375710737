const hasBarcodeFilter = {
  namespaced: true,
  state: {
    notNull: false,
  },
  mutations: {
    SET_NOTNULL(state, notNull) {
      state.notNull = notNull;
    }
  },
  actions: {
    setNotNull(context, value) {
      context.commit("SET_NOTNULL", value);
    }
  },
  getters: {
    notNull: state => {
      return state.notNull
    },
    toParams: state => {
      const params = [];

      if (state.notNull === true) {
        params.push({column: "exists[barcode]", value: !state.notNull})
      }
      return params;
    }
  }
};

export default hasBarcodeFilter;
