import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import nameFilter from "@/store/modules/locationsOverview/filters/nameFilter";
import groupFilter from "@/store/modules/locationsOverview/filters/groupFilter";
import locationCodeFilter from "@/store/modules/locationsOverview/filters/locationCodeFilter";
import locationShortNameFilter from "@/store/modules/locationsOverview/filters/locationShortNameFilter";
import isAllocationAllowedFilter from "@/store/modules/locationsOverview/filters/isAllocationAllowedFilter";
import isActiveFilter from "@/store/modules/locationsOverview/filters/isActiveFilter";
import inStoreLocatorFilter from "@/store/modules/locationsOverview/filters/inStoreLocatorFilter";
import drivingRoutes from "@/store/modules/locationsOverview/drivingRoutes";
import drivingRoutesExceptions from "@/store/modules/locationsOverview/drivingRoutesExceptions";
import openingHours from "@/store/modules/locationsOverview/openingHours";
import openingHoursExceptions from "@/store/modules/locationsOverview/openingHoursExceptions";
import entityService from "@/services/EntityService";

const locationOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    nameFilter,
    groupFilter,
    locationCodeFilter,
    locationShortNameFilter,
    isAllocationAllowedFilter,
    isActiveFilter,
    inStoreLocatorFilter,
    drivingRoutes,
    drivingRoutesExceptions,
    openingHours,
    openingHoursExceptions
  },
  state: {
    ...tableState,
    pagination: {itemsPerPage: 20, itemsPage: 1},
    footerProps: {"items-per-page-options": [10, 20, 40, 80]},
    location: null
  },
  mutations: {
    ...tableMutations,
    SET_LOCATION(state, location) {
      state.location = location;
    }
  },
  actions: {
    ...tableActions,
    fetchItems({state, commit}, refresh = false) {
      if (refresh || state.items.length === 0) {

        commit("SET_LOADING", true);
        const params = {pagination: state.pagination, sorting: state.sorting, search: state.search};

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        entityService.locations.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });

      }

    },
    selectLocation({ commit }, item) {
      commit("SET_ITEM", item);
      commit("SET_LOCATION", item);
    },
    edit({commit}, location) {
      return new Promise((resolve, reject) => {
        if (location.locationLine2 === "") {
          location.locationLine2 = null;
        }
        location.priority = (location.priority === null || location.priority.length === 0) ? null : Number.parseInt(location.priority);
        location.longitude = (!location.longitude || location.longitude === null || location.longitude.length === 0) ? null : location.longitude;
        location.latitude = (!location.latitude || location.latitude === null || location.latitude.length === 0) ? null : location.latitude;

        entityService.locations.update(location.code, location)
          .then(() => {
            commit("SET_LOCATION", location);
            commit("EDIT_ITEM", location);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    add({commit}, location) {
      return new Promise((resolve, reject) => {
        location.priority = (location.priority === null || location.priority.length === 0) ? null : Number.parseInt(location.priority);
        location.longitude = (location.longitude === null ||location.longitude.length === 0) ? null : location.longitude;
        location.latitude = (location.latitude === null || location.latitude.length === 0) ? null : location.latitude;

        entityService.locations.create(location)
          .then((data) => {
            commit("SET_LOCATION", data);
            commit("ADD_ITEM", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    patch({commit}, location) {
      return new Promise((resolve, reject) => {
        entityService.locations.patch(location.code, location, { headers: { "content-type": "application/merge-patch+json" }})
            .then((location) => {
              commit("SET_LOCATION", location);
              commit("EDIT_ITEM", location);
              resolve();
            })
            .catch(err => {
              reject(err["hydra:description"]);
            });
      });
    },
  },
  getters: {
    ...tableGetters,
    getLocationById: state => id => {
      return state.items.find(item => item.id === id);
    }
  }
};

export default locationOverview;
