const locationsFilter = {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    }
  },
  actions: {
    setData(context, value) {
      context.commit("SET_DATA", value);
    }
  },
  getters: {
    data: state => {
      return state.data;
    },
    toParams: state => {
      let searchParams = [];
      state.data.forEach((item) => {
        // Filter bundle magic: https://github.com/metaclass-nl/filter-bundle
        searchParams.push({ column: "locations.code[]", value: item });
      });
      return searchParams
    }
  }
};

export default locationsFilter;
