import { tableState, tableMutations, tableActions, tableGetters } from "@tt/vue-components";
import entityService from "@/services/EntityService";

const locationsList = {
  namespaced: true,
  state: {
    ...tableState,
    search: [
      { column: 'props[]', value: 'id' },
      { column: 'props[]', value: '@id' },
      { column: 'props[]', value: 'code' },
      { column: 'props[]', value: 'name' },
    ]
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = {
          pagination: { page: 1, itemsPerPage: 999},
          sorting: { sortBy: ["name"], sortDesc: [false] },
          search: context.state.search
        };
        entityService.locations.list(params)
          .then(json => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);

            // preselect
            if (context.state.source) {
              json.items.forEach(source => {
                if (source.id === context.state.source.id) {
                  context.dispatch("selectSource", source);
                }
              });
            }
          })
          .catch(err => {
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
  },
  getters: {
    ...tableGetters,
    getLocationByCode: (state) => (code) => {
      return state.items.find(item => item.code === code)
    },
    getLocationById: state => id => {
      return state.items.find(item => item.id === id);
    }
  }
};

export default locationsList;
